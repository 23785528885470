import React from 'react'
import VisibilitySensor from 'react-visibility-sensor'
import { Fade } from './animations.css'

export const FadeIn = ({
  children,
  style,
  index,
  direction,
  alwaysVisible,
  visibleAfter,
  callBack,
}) => {
  return (
    <VisibilitySensor
      partialVisibility={true}
      key={index}
      onChange={isVisible => {
        callBack && callBack(isVisible)
      }}
    >
      {({ isVisible }) => (
        <Fade
          isVisible={alwaysVisible || visibleAfter || isVisible}
          direction={direction}
          style={style}
        >
          {children}
        </Fade>
      )}
    </VisibilitySensor>
  )
}
