import React, { useState } from 'react'
import PropTypes from 'prop-types'
import htmr from 'htmr'
import { FadeIn } from '../animations'
import * as S from './paragraph.css.js'

const ParagraphBlock = ({
  heading,
  subHeading,
  content,
  buttonText,
  buttonSubmit,
  buttonLink,
  contentType,
  noFade,
  isLast,
}) => {
  const [stayVisible, setStayVisible] = useState(false)
  return (
    <S.Paragraph last={isLast}>
      {(heading || subHeading) && (
        <S.Heading>
          {heading && heading}
          {subHeading && <S.SubHeading>{subHeading}</S.SubHeading>}
        </S.Heading>
      )}
      {content && (
        <S.P fullWidth={!heading && !subHeading}>
          <FadeIn
            alwaysVisible={noFade}
            visibleAfter={stayVisible}
            callBack={isVisible => isVisible && setStayVisible(true)}
          >
            {contentType === 'component' ? content : htmr(content)}
            {buttonText && (buttonLink || buttonSubmit) ? (
              buttonLink ? (
                <S.A to={buttonLink}>{buttonText}</S.A>
              ) : (
                <S.Button type="submit">{buttonText}</S.Button>
              )
            ) : null}
          </FadeIn>
        </S.P>
      )}
    </S.Paragraph>
  )
}

ParagraphBlock.propTypes = {
  heading: PropTypes.string,
  subHeading: PropTypes.string,
  content: PropTypes.string,
  contentType: PropTypes.string,
  buttonText: PropTypes.string,
  buttonLink: PropTypes.string,
}

ParagraphBlock.defaultProps = {
  heading: '',
  subHeading: '',
  content: '',
  buttonText: '',
  buttonLink: '',
}

export default ParagraphBlock
