import styled, { css } from 'styled-components'
import { transitions } from '../../../lib/constants'

export const Fade = styled.div`
  position: relative;
  opacity: 0;
  transition: transform ${transitions.slow}, opacity ${transitions.slow};
  transition-delay: 0.15s;

  ${props => {
    const { isVisible, direction } = props
    // direction = up (default)
    if ((direction && direction === 'up') || !direction) {
      return css`
        transform: translateY(25%);
        ${isVisible && 'transform: translateY(0%); opacity: 1;'}
      `
    }

    // direction = down
    if (direction && direction === 'down') {
      return css`
        transform: translateY(-25%);
        ${isVisible && 'transform: translateY(0%); opacity: 1;'}
      `
    }

    // direction = right
    if (direction && direction === 'right') {
      return css`
        transform: translateX(-25%);
        ${isVisible && 'transform: translateX(0%); opacity: 1;'}
      `
    }

    // direction = left
    if (direction && direction === 'left') {
      return css`
        transform: translateX(25%);
        ${isVisible && 'transform: translateX(0%); opacity: 1;'}
      `
    }
  }}
`
