import React from 'react'
import htmr from 'htmr'
import { graphql } from 'gatsby'
import Hero from '../components/Global/Hero'
import Paragraph from '../components/paragraph/paragraph'
import { Layout, Container } from '../layouts'

const LocalPage = ({ data }) => {
  const { html, frontmatter } = data.markdownRemark
  return (
    <Layout>
      <Hero
        title={`Web Design for ${frontmatter.location}`}
        image={frontmatter.image ? frontmatter.image.publicURL : null}
        small
      />
      <Container>
        {html && (
          <Paragraph
            heading={frontmatter.location}
            subHeading={'That local touch...'}
            content={htmr(html)}
            contentType={'component'}
          />
        )}
      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        slug
        location
        image {
          publicURL
        }
      }
    }
  }
`

export default LocalPage
